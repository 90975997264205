import React from "react";
import { StyledDiv } from "./InfoBarStyled";
import { BsChevronDoubleRight } from "react-icons/bs";
import moment from "moment";

const InfoBar = ({ date, origin, destination }) => {
    return (
        <StyledDiv className="Wrapper">
            <StyledDiv className="Cell">{moment(date).format("MMM D, YYYY hh:mm a (H:m)")}</StyledDiv>
            <StyledDiv className="Cell hideOnMobile">|</StyledDiv>
            <StyledDiv className="Cell left">
                <p>{origin}</p>
            </StyledDiv>
            <StyledDiv className="Cell">
                <BsChevronDoubleRight />
            </StyledDiv>
            <StyledDiv className="Cell left">
                <p>{destination}</p>
            </StyledDiv>
        </StyledDiv>
    );
};

export default InfoBar;
