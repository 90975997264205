import React, { useEffect, useState } from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import VehicleCard from "../components/CarCard/VehicleCard";
import TopBanner from "../components/TopBanner/TopBanner";
import styled from "styled-components";
import ProgressSteps from "../components/ProgressSteps/ProgressSteps";
import InfoBar from "../components/InfoBar/InfoBar";
import moment from "moment";

const VehicleList = () => {
    const [state, setState] = useState(null);
    const query = graphql`
        {
            allStrapiVehicles(sort: {fields: order, order: ASC}) {
                nodes {
                    id
                    title
                    description
                    maxLuggage
                    maxPeople
                    enumCategory
                    amenities {
                        amenity
                        icon
                    }
                    image {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                                ...GatsbyImageSharpFluidLimitPresentationSize
                            }
                        }
                    }
                }
            }
        }
    `;

    const data = useStaticQuery(query);
    const {allStrapiVehicles: { nodes: vehicles }} = data;

    const onSelect = (vehiclePrice, maxLuggage, maxPassengers, vehicleId, enumCategory, vehicleName) => {
        const data = JSON.stringify({
            date: state.date,
            fromData: state.fromData,
            toData: state.toData,
            price: vehiclePrice,
            luggageCount: state.luggageCount,
            passengersCount: state.passengersCount,
            maxLuggage: maxLuggage,
            maxPassengers: maxPassengers,
            vehicleType: enumCategory,
            vehicleId: vehicleId,
            vehicleName: vehicleName
        });
        localStorage.setItem("checkoutData", data);
        navigate("/checkout");
    };

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem("orderData"));
        if (
            !data ||
            !data.fromData ||
            !data.toData ||
            !data.date ||
            !data.luggageCount ||
            !data.passengersCount ||
            (data.date && moment().isAfter(data.date))
        ) {
            alert("Please Check The Selected Time, Must be at least one hour later")
            localStorage.removeItem("orderData");
            navigate("/");
        } else {
            setState(data);
        }
    }, []);

    if (!state) {
        return null;
    }

    return (
        <StyledDiv className="Wrapper">
            <ProgressSteps active={2} />
            <StyledDiv className="TopBannerWrapper">
                {/*<TopBanner />*/}
                <InfoBar
                    date={state && state && state.date ? state.date : null}
                    origin={
                        state && state.fromData && state.fromData.e && state.fromData.e.description ? state.fromData.e.description : null
                    }
                    destination={state && state.toData && state.toData.e && state.toData.e.description ? state.toData.e.description : null}
                />
            </StyledDiv>
            <StyledDiv className="CarsWrapper">
                {vehicles.map((vehicle) => {
                    const { id, amenities, title, description, maxLuggage, maxPeople, image, enumCategory } = vehicle;
                    const fluid = image.childImageSharp.fluid;
                    if (state && state.fetchedPrices) {
                        const vehicleData = state.fetchedPrices.find((e) => e.vehicleCategory.id.toString() === enumCategory);
                        let vehiclePrice = null;
                        if (vehicleData && vehicleData.price) {
                            vehiclePrice = vehicleData.price;
                        }
                        if (state.luggageCount > maxLuggage || state.passengersCount > maxPeople) return;

                        return (
                            <VehicleCard
                                key={id}
                                fluid={fluid}
                                category={title}
                                description={description}
                                maxLuggage={maxLuggage}
                                amenities={amenities}
                                maxPeople={maxPeople}
                                price={vehiclePrice}
                                onSelect={() => onSelect(vehiclePrice, maxLuggage, maxPeople, id, enumCategory, title)}
                            />
                        );
                    }
                })}
            </StyledDiv>
        </StyledDiv>
    );
};

const StyledDiv = styled.div`
    &.Wrapper {
        background-color: #e9ecef;
        min-height: 100vh;
    }
    &.CarsWrapper {
        display: grid;
        grid-auto-rows: auto;
        grid-gap: 1rem;
        width: 50%;
        min-width: 800px;
        max-width: 1200px;
        margin: auto;
        padding-bottom: 1rem;
    }
    &.TopBannerWrapper {
        margin: auto;
        width: 50%;
        min-width: 800px;
        max-width: 1200px;
        margin-top: 1rem;
    }
    @media (max-width: 800px) {
        &.CarsWrapper,
        &.TopBannerWrapper {
            width: 100%;
            min-width: auto;
        }
    }
`;

export default VehicleList;
