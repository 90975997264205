import styled from "styled-components";

const StyledDiv = styled.div`
    &.Wrapper {
        padding: 0.75rem 0;
        display: grid;
        width: 100%;
        grid-template-columns: 225px 15px auto 2rem auto;
        justify-content: space-evenly;
        span {
            font-size: 1.35rem;
            line-height: 1;
        }
    }

    &.Cell {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        svg {
            height: 1.25rem;
            width: 1.25rem;
        }

        &.left {
            justify-content: flex-start;
        }

        font-size: 0.95rem;
        word-wrap: nowrap;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        p {
            font-size: 0.95rem;
            word-wrap: nowrap;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    @media (max-width: 800px) {
        &.Wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &.hideOnMobile {
            display: none;
        }
        &.Cell {
            margin: 0.1rem 0;
            width: 80%;
            p {
                margin: auto;
            }
            svg {
                transform: rotate(90deg);
                height: 1rem;
                width: 1rem;
            }
        }
    }
`;

export { StyledDiv };
